import React, { useRef, useEffect } from 'react';
import flow01 from '../../assets/flow01.png';
import { Splide, SplideTrack, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/css/core';
import './HowTo.css';

function HowTo() {
  const splideRef = useRef(null);

  useEffect(() => {
    const splide = splideRef.current?.splide;

    if (splide) {
      const updateArrows = () => {
        const prevArrow = document.querySelector('.splide__arrow--prev');
        const nextArrow = document.querySelector('.splide__arrow--next');

        if (splide.index === 0) {
          prevArrow.style.visibility = 'hidden';
          prevArrow.style.pointerEvents = 'none';
        } else {
          prevArrow.style.visibility = 'visible';
          prevArrow.style.pointerEvents = 'auto';
        }

        if (splide.index === splide.length - 1) {
          nextArrow.style.visibility = 'hidden';
          nextArrow.style.pointerEvents = 'none';
        } else {
          nextArrow.style.visibility = 'visible';
          nextArrow.style.pointerEvents = 'auto';
        }
      };

      splide.on('mounted move', updateArrows);
      updateArrows(); // 初期状態の更新

      // クリーンアップ関数
      return () => {
        splide.off('mounted move', updateArrows);
      };
    }
  }, []);
  return (
    <div className="wrapper how2">
      <h3 className="h3">作成のながれ</h3>

      <Splide
        hasTrack={false}
        aria-label="作成のながれ"
        arrows={true}
        options={{
          arrowPath: 'm15.5 0.932-4.3 4.38...'
        }}
        ref={splideRef}
      >
        <div className="custom-wrapper">
          <SplideTrack>
            <SplideSlide>
              <img src={flow01} alt="1月から12月までの写真をえらぶ" className="img how2img" />
              <p className="how2txt">① 1月から12月までの<br />写真をえらぶ</p>
            </SplideSlide>
            <SplideSlide>
              <img src={flow01} alt="0から9までの手書き写真をアップロードする" className="img how2img" />
              <p className="how2txt">② 0から9までの手書き写真を<br />アップロードする</p>
            </SplideSlide>
            <SplideSlide>
              <img src={flow01} alt="オプション選択" className="img how2img" />
              <p className="how2txt">③ オプション選択</p>
            </SplideSlide>
          </SplideTrack>
          <div className="splide__arrows howto_arrow"></div>
        </div>
      </Splide>

    </div>
  );
}

export default HowTo;
