import React, { useState, useCallback, useEffect } from 'react';
import './Build.css';
import Cropper from 'react-easy-crop';
import { getCroppedImg } from '../utils/cropImage';

function MonthGrid({ monthImages, setMonthImages }) {
  const months = [
    '1月', '2月', '3月', '4月',
    '5月', '6月', '7月', '8月',
    '9月', '10月', '11月', '12月',
  ];

  const [selectedImage, setSelectedImage] = useState(null);
  const [currentMonthNumber, setCurrentMonthNumber] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [hasUserAdjustedCrop, setHasUserAdjustedCrop] = useState(false);

  const handleMonthImageUpload = useCallback((monthNumber, imageSrc) => {
    setMonthImages((prev) => ({ ...prev, [monthNumber]: imageSrc }));
  }, [setMonthImages]);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
    setHasUserAdjustedCrop(true);
  }, []);

  useEffect(() => {
    if (selectedImage && croppedAreaPixels && hasUserAdjustedCrop) {
      const saveCroppedImage = async () => {
        try {
          console.log('croppedAreaPixels', croppedAreaPixels);
          console.log('zoom', zoom);
          const croppedImage = await getCroppedImg(
            selectedImage,
            croppedAreaPixels,
            zoom
          );
          handleMonthImageUpload(currentMonthNumber, croppedImage);
        } catch (e) {
          console.error(e);
        }
      };
      saveCroppedImage();
    }
  }, [croppedAreaPixels, zoom, hasUserAdjustedCrop, currentMonthNumber, handleMonthImageUpload, selectedImage]);

  const handleUpload = (e, monthNumber) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const img = new Image();
        img.src = reader.result;
        img.onload = () => {
          setCroppedAreaPixels({
            x: 0,
            y: 0,
            width: img.width,
            height: img.height,
          });

          setSelectedImage(reader.result);
          setCurrentMonthNumber(monthNumber);

          setCrop({ x: 0, y: 0 });
          setHasUserAdjustedCrop(false);
        };
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <div id="month-grid" className="month-grid">
      <h4 className="h4">① 1月から12月までの写真をえらぼう</h4>

      {selectedImage ? (
        <div className="trimming2">
          {selectedImage && (
            <Cropper
              image={selectedImage}
              crop={crop}
              maxZoom={7}
              zoom={zoom}
              aspect={1}
              onCropChange={setCrop}
              onZoomChange={setZoom}
              onCropComplete={onCropComplete}
            />
          )}
        </div>
      ) : (
        <div className="trimming"></div>
      )}

      <div style={{ display: 'grid', gridTemplateColumns: 'repeat(6, 1fr)', gap: '10px' }}>
        {months.map((monthName, index) => {
          const monthNumber = index + 1;
          const imageSrc = monthImages[monthNumber];

          return (
            <div className="monthlist" key={monthNumber}>
              <div
                className="monthitem"
                style={
                  imageSrc
                    ? {
                      backgroundImage: `url(${imageSrc})`,
                      backgroundSize: 'cover',
                      backgroundPosition: 'center',
                      cursor: 'pointer',
                      border: '2px solid #423E3B',
                      color: '#ffffff'
                    }
                    : {}
                }
                onClick={() => document.getElementById(`month-upload-${monthNumber}`).click()}
              >
                <p>{monthName}</p>
                <input
                  type="file"
                  accept="image/*"
                  id={`month-upload-${monthNumber}`}
                  style={{ display: 'none' }}
                  onChange={(e) => handleUpload(e, monthNumber)}
                />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default MonthGrid;
