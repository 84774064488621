import React, { useRef, useState } from 'react';
import './Build.css'; // スタイルシートのパスを調整してください

function ImageUploader({ onRecognitionComplete }) {
  const [isProcessing, setIsProcessing] = useState(false);
  const fileInputRef = useRef(null);
  const cameraInputRef = useRef(null);

  const [images, setImages] = useState({}); // 認識結果の画像
  const [additionalImages, setAdditionalImages] = useState({}); // ユーザーが追加した画像
  const allImages = { ...images, ...additionalImages };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      // 必要に応じて画像プレビューを表示する場合はここで処理
      handleRecognition();
    }
  };

  const handleRecognition = async () => {
    if (!fileInputRef.current?.files[0] && !cameraInputRef.current?.files[0]) {
      alert('先に画像をアップロードしてください。');
      return;
    }

    setIsProcessing(true);

    const formData = new FormData();
    const file = fileInputRef.current?.files[0] || cameraInputRef.current?.files[0];
    formData.append('image', file);

    try {
      const baseURL = process.env.REACT_APP_API_URL || '';
      const response = await fetch(`${ baseURL }/recognize`, {
        method: 'POST',
        body: formData,
      });
      const result = await response.json();
      // 認識結果の画像を状態に保存
      setImages(result.images);

      // 認識結果を親コンポーネントに渡す
      if (onRecognitionComplete) {
        onRecognitionComplete(result);
      }
    } catch (error) {
      console.error('認識に失敗しました:', error);
    } finally {
      setIsProcessing(false);
    }
  };

  const handleUpload = (digit, e) => {
    const file = e.target.files[0];
    if (file) {
      const img = new Image();
      img.onload = () => {
        const canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;

        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0);

        const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
        const data = imageData.data;

        // 背景透過処理
        const threshold = 180; // 輝度の閾値
        for (let i = 0; i < data.length; i += 4) {
          const brightness =
            0.299 * data[i] + 0.587 * data[i + 1] + 0.114 * data[i + 2];
          if (brightness > threshold) {
            data[i + 3] = 0; // 透明にする
          }
        }
        ctx.putImageData(imageData, 0, 0);

        const src = canvas.toDataURL();
        setAdditionalImages((prev) => ({ ...prev, [digit]: src }));
      };
      img.src = URL.createObjectURL(file);
    }
  };

  return (
    <div className="numberimage">
      <h4 className="h4">② 0から9までの手書き写真をアップロードする</h4>
      <p className="up_lead">
        見本のように、1つ1つの文字を離して、なるべくはっきり映るようにしましょう
      </p>

      <div className="trimming trimming02"></div>

      {/* ファイル入力要素を非表示で追加 */}
      <input
        type="file"
        accept="image/*"
        onChange={handleImageUpload}
        ref={fileInputRef}
        style={{ display: 'none' }}
      />

      {/* カメラ入力要素を非表示で追加 */}
      <input
        type="file"
        accept="image/*"
        capture="environment"
        onChange={handleImageUpload}
        ref={cameraInputRef}
        style={{ display: 'none' }}
      />

      <div className="imageupload-btn">
        <button
          className="imageupload album"
          onClick={() => fileInputRef.current && fileInputRef.current.click()}
        >
          アルバムから選択する
        </button>

        <button
          className="imageupload camera"
          onClick={() => cameraInputRef.current && cameraInputRef.current.click()}
        >
          カメラで写真を撮る
        </button>
      </div>

      {isProcessing && <p>認識中...</p>}

      <div className="number_list">
        {Array.from({ length: 10 }, (_, i) => i).map((digit) => (
          <div key={digit} className="digit-container">
            <p>{digit}</p>
            {allImages[digit] ? (
              <img
                src={allImages[digit]}
                alt={`number-${digit}`}
                width={50}
                className="digit-image"
                onClick={() =>
                  document.getElementById(`upload-${digit}`).click()
                }
              />
            ) : (
              <div
                className="upload-placeholder"
                onClick={() =>
                  document.getElementById(`upload-${digit}`).click()
                }
              >
                <span>+</span>
              </div>
            )}
            <input
              type="file"
              accept="image/*"
              id={`upload-${digit}`}
              className="file-input"
              onChange={(e) => handleUpload(digit, e)}
              style={{ display: 'none' }}
            />
          </div>
        ))}
      </div>
    </div>
  );
}

export default ImageUploader;
