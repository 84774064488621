import React from 'react';
import about01 from '../../assets/about01.png';
import './About.css'; // スタイルシートをインポート

function About() {
  return (
    <div className="wrapper back About">
      <h3 className="h3">今しかない、この文字で<br/>世界に1つだけのカレンダー</h3>
      <img src={about01} alt="今しかない、この文字で世界に1つだけのカレンダー" className="img aboutimg" />
      <div className="lead">
        <p className="lead_item">ちょっとずつ覚えた数字、<br />まだ練習中の文字、<br />いつの間にか上手に書けるように...</p>
        <p>何歳になっても、<span className="bold">今しかないこの文字</span>を<br />カレンダーにして残そう。</p>
      </div>
      <div className="name_history">
        <h4 className="h4">「ころみ」</h4>
        <p className="name_contents">暦(こよみ)を子どもが言い間違ったら...ころみになっちゃった!?意味がわかるとクスッと笑える、かわいい名前です。</p>
      </div>
    </div>
  );
}

export default About;
