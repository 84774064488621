import React from 'react';
import { scrollToMonthGrid } from '../../utils/scrollUtils';
import './MainVisual.css'; // スタイルシートをインポート

function MainVisual() {
  return (
    <div className="MainVisual scroll">
        <button className="mv_cvbtn" onClick={scrollToMonthGrid}>カレンダーをつくる</button>
    </div>
  );
}

export default MainVisual;
