import React from 'react';
import logo from '../../assets/logo.png';
import './Header.css'; // スタイルシートをインポート
import { scrollToMonthGrid } from '../../utils/scrollUtils';

function Header() {
  return (
    <header className="flex header">
      <img src={logo} alt="logo" className="logo" />
      <button className="link-button" onClick={scrollToMonthGrid}>つくる</button>
    </header>
  );
}

export default Header;
