import React from 'react';
import './Billing.css'; // スタイルシートをインポート

function Billing({ framePrice }) {
  return (
    <div className="billing">
      <ul className="wrapper billing_list">
        <li className="flex billing_total">
          <p>合計金額</p>
          <p>{500 + framePrice} 円（税込）</p>
        </li>
        <li className="flex billing_standard">
          <p>ダウンロード料金</p>
          <p>500円</p>
        </li>
        <li className="flex billing_add">
          <p>フレーム追加料金</p>
          {/* TOOD: 変数にする */}
          <p>{framePrice}円</p>
        </li>
      </ul>
    </div>
  );
}

export default Billing;
