import React from 'react';
import { startOfMonth, endOfMonth, eachDayOfInterval, format } from 'date-fns';
import './Calendar.css';
import { Splide, SplideTrack, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/css/core';

function Calendar({ images, position, monthImages, startMonth, startDay, language }) { // language を追加
  const today = new Date();
  let year = today.getFullYear(); // 年を取得

  const months = Array.from({ length: 12 }, (_, i) => (i + startMonth - 1) % 12); // startMonthから開始

  // 曜日ヘッダーの生成を言語に応じて変更
  const weekDaysJapanese = ['日', '月', '火', '水', '木', '金', '土'];
  const weekDaysEnglish = ['Sun.', 'Mon.', 'Tue.', 'Wed.', 'Thu.', 'Fri.', 'Sat.'];
  const weekDays = language === '英語' ? weekDaysEnglish : weekDaysJapanese;

  const orderedWeekDays = weekDays.slice(startDay).concat(weekDays.slice(0, startDay));

  return (
    <div className="calendar-wrapper">
      <Splide
        hasTrack={false}
        aria-label="プレビュー"
        arrows={true}
        options={{
          arrowPath: 'm15.5 0.932-4.3 4.38...'
        }}
      >
        <div className="">
          <SplideTrack>
            {months.map((monthIndex, i) => {
              let currentYear = year;
              let currentMonthDate = new Date(currentYear, monthIndex, 1);
              const endOfCurrentMonth = endOfMonth(currentMonthDate);

              if (endOfCurrentMonth < today) {
                currentYear = currentYear + 1;
                currentMonthDate = new Date(currentYear, monthIndex, 1);
              }

              const start = startOfMonth(currentMonthDate);
              const end = endOfMonth(currentMonthDate);
              const days = eachDayOfInterval({ start, end });

              // ユーザ選択の開始曜日に基づいて調整
              const actualStartDay = startDay; // ユーザが選択した開始曜日
              const startWeekday = start.getDay();
              const paddingCount = (startWeekday - actualStartDay + 7) % 7;
              const paddingDays = Array.from({ length: paddingCount }, () => null);

              const yearStr = format(currentMonthDate, 'yyyy');
              const monthStr = format(currentMonthDate, 'MM');
              const yearDigits = yearStr.split('');
              const monthDigits = monthStr.split('');

              const monthNumber = currentMonthDate.getMonth() + 1;
              const currentMonthImage = monthImages[monthNumber];

              return (
                <SplideSlide key={monthIndex}>
                  <div className="flex preview">
                    {currentMonthImage && (
                      <div className="month-image">
                        <img src={currentMonthImage} alt={`Month ${monthNumber}`} className="pre-image" />
                      </div>
                    )}

                    <div className="pre-right">
                      <h2 className="pre-h2">
                        {language === '英語' ? (
                          `${year} Year ${monthNumber} Month`
                        ) : (
                          <>
                            {yearDigits.map((digit, index) =>
                              images[digit] ? (
                                <img key={`year-${index}`} src={images[digit]} alt={digit} width={11} />
                              ) : (
                                <span key={`year-${index}`}>{digit}</span>
                              )
                            )}
                            年
                            {monthDigits.map((digit, index) =>
                              images[digit] ? (
                                <img key={`month-${index}`} src={images[digit]} alt={digit} width={20} />
                              ) : (
                                <span key={`month-${index}`}>{digit}</span>
                              )
                            )}
                            月
                          </>
                        )}
                      </h2>
                      <div className="calendar pre-calendar">
                        {/* 曜日ヘッダーの表示 */}
                        {orderedWeekDays.map((day, index) => (
                          <div key={`header-${index}`} className="calendar-cell header">
                            {day}
                          </div>
                        ))}
                        {/* 空白セルの挿入 */}
                        {paddingDays.map((_, index) => (
                          <div key={`padding-${index}`} className="calendar-cell empty"></div>
                        ))}
                        {days.map((day) => {
                          const dateNumber = format(day, 'd'); // 日付を数字として取得（先頭にゼロなし）
                          const digits = dateNumber.split('');

                          // アップロードされた画像に日付が含まれているかチェック
                          if (images[dateNumber]) {
                            // 日付全体に対応する画像がある場合、その画像を表示
                            return (
                              <div key={day} className="calendar-cell">
                                <img src={images[dateNumber]} alt={dateNumber} className="full-date-image" />
                              </div>
                            );
                          } else {
                            // 各桁の数字画像を組み合わせて表示
                            return (
                              <div key={day} className="calendar-cell">
                                {digits.map((digit, index) =>
                                  images[digit] ? (
                                    <img key={index} src={images[digit]} alt={digit} width={20} />
                                  ) : (
                                    <span key={index}>{digit}</span>
                                  )
                                )}
                              </div>
                            );
                          }
                        })}
                      </div>
                    </div>
                  </div>
                </SplideSlide>
              );
            })}
          </SplideTrack>
          <div className="calendar_arrow splide__arrows"></div>
        </div>
      </Splide>
    </div>
  );
}

export default Calendar;
