import React, { useState, useEffect, useRef, useCallback } from 'react';
import "react-toastify/dist/ReactToastify.css";
import './App.css';
import Calendar from './components/Calendar/Calendar';
import ImageUploader from './components/ImageUploader';
import MonthGrid from './components/MonthGrid';
import Header from './components/Header/Header';
import HowTo from './components/HowTo/HowTo';
import MainVisual from './components/MainVisual/MainVisual';
import About from './components/About/About';
import OptionSelect from './components/OptionSelect';
import FAQ from './components/FAQ/FAQ';
import Footer from './components/Footer/Footer';
import Billing from './components/Billing';
import { ToastContainer } from 'react-toastify';
import Download from './components/Download/Download';

function App() {
  const [images, setImages] = useState({});
  const [additionalImages, ] = useState({});
  const [, setRecognizedDigits] = useState([]);
  const [positions, ] = useState({});
  const [monthImages, setMonthImages] = useState({});
  const [startMonth, setStartMonth] = useState(0);
  const [startDay, setStartDay] = useState(0);
  const [language, setLanguage] = useState('英語');
  const [selectedFrame, setSelectedFrame] = useState(0);
  const [framePrice, setFramePrice] = useState(0);
  const calendarRef = useRef(null);

  const onOptionsChange = useCallback((options) => {
    const framePrices = OptionSelect.frames.map(frame => frame.price);
    setFramePrice(framePrices[options.selectedFrame]);
    setStartDay(options.startDay); // 追加
  }, []);

  useEffect(() => {
    const framePrices = OptionSelect.frames.map(frame => frame.price);
    setFramePrice(framePrices[selectedFrame]);
  }, [selectedFrame]);

  const handleRecognitionComplete = (result) => {
    setRecognizedDigits(result.digits);
    setImages(result.images || {});
  };

  return (
    <div className="container">
      <ToastContainer
        autoClose={3000}
      />
      <Header />
      <MainVisual />
      <About />
      <HowTo />

      <div className="wrapper build">

        <h3 className="h3 main-f-color">カレンダーをつくろう</h3>

        {/* build① */}
        <MonthGrid monthImages={monthImages} setMonthImages={setMonthImages} />

        {/* build② */}
        <ImageUploader onRecognitionComplete={handleRecognitionComplete} />

        {/* complete iamge */}
        <div className="finish_image">
          <h3 className="h3 main-f-color">完成イメージ</h3>
          <div ref={calendarRef}>
            {/* カレンダー */}
            <Calendar
              images={{ ...images, ...additionalImages }}
              positions={positions}
              monthImages={monthImages}
              startMonth={startMonth}
              startDay={startDay}
              language={language}
            />
          </div>
        </div>

        {/* build③ */}
        <OptionSelect
          startMonth={parseInt(startMonth, 10)}
          setStartMonth={setStartMonth}
          startDay={startDay}
          setStartDay={setStartDay}
          language={language}
          setLanguage={setLanguage}
          selectedFrame={selectedFrame}
          setSelectedFrame={setSelectedFrame}
          onOptionsChange={onOptionsChange}
        />
      </div>

      <Billing framePrice={framePrice} />

      {/* TODO: ダウンロード */}
      <Download calendarRef={calendarRef} framePrice={framePrice} />

      <FAQ />
      <Footer />
    </div>
  );
}

export default App;
