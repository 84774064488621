// cropImage.js
const createImage = (url) =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.setAttribute('crossOrigin', 'anonymous'); // クロスオリジンの問題を回避
    image.onload = () => resolve(image);
    image.onerror = (error) => reject(error);
    image.src = url;
  });

export const getCroppedImg = async (imageSrc, croppedAreaPixels, zoom) => {
  const image = await createImage(imageSrc);
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  // 画像のスケールを計算
  const scaleX = image.naturalWidth / image.width;
  console.log('scaleX', scaleX);
  const scaleY = image.naturalHeight / image.height;
  console.log('scaleY', scaleY);

  // キャンバスのサイズをズームに基づいて設定
  canvas.width = croppedAreaPixels.width
  canvas.height = croppedAreaPixels.height

  ctx.imageSmoothingQuality = 'high'

  // 表示されている領域をズームに基づいて調整
  const cropX = croppedAreaPixels.x * scaleX;
  const cropY = croppedAreaPixels.y * scaleY;
  const cropWidth = croppedAreaPixels.width * scaleX;
  const cropHeight = croppedAreaPixels.height * scaleY;

  console.log('cropX', cropX);
  console.log('cropY', cropY);
  console.log('cropWidth', cropWidth);
  console.log('cropHeight', cropHeight);
  console.log('canvas.width', canvas.width);
  console.log('canvas.height', canvas.height);

  // 画像を描画 (ズームをそのまま適用して見えている範囲を描画)
  ctx.drawImage(
    image,
    cropX,
    cropY,
    cropWidth,
    cropHeight,
    0,
    0,
    canvas.width,
    canvas.height
  );

  // クロップされた画像をBlobとして返す
  return new Promise((resolve, reject) => {
    canvas.toBlob((blob) => {
      if (!blob) {
        reject(new Error('Canvasが空です'));
        return;
      }
      const fileUrl = URL.createObjectURL(blob);
      resolve(fileUrl);
    }, 'image/jpeg');
  });
};
